import React, { useEffect } from "react";
import AOS from 'aos';
import Banner from "../assets/banner.png"
export default function BannerSection() {
  useEffect(() => {
    AOS.init({
      // Configuration options go here
    });
  },[])
    return (
        <div className="row">
        <div className="col-lg-6 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">Senatus: Peer to peer deals, voting and proposals</h1>
          <h2 data-aos="fade-up" data-aos-delay="400">Easy, direct and transparent. Never on a server.</h2>
        </div>
        <div className="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
          <img src={Banner} className="img-fluid"  alt="" />
        </div>
      </div>
    )
}