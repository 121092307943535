import React, {useState, useEffect} from 'react'
import Logo from '../assets/senatus-logo-small.png';
export default function Header() {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
          setScroll(window.scrollY > 50);
        });
      }, []);
    return (
        <header id="header" className={scroll ? "header fixed-top header-scrolled" : "header fixed-top"}>
    <div className="container-fluid container-xl d-flex align-items-center justify-content-around">

      <a href="index.html" className="logo d-flex align-items-center">
        <img src={Logo} alt="" />
      </a>

      {/* <nav id="navbar" className="navbar">
        <ul>
          <li><a className="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a className="nav-link scrollto" href="#about">Connection Status Check</a></li>
          <li><a className="nav-link scrollto" href="#services">Terms</a></li>
          <li><a className="nav-link scrollto" href="#portfolio">Previous Versions</a></li>
          <li><a className="nav-link scrollto" href="#team">Team</a></li>
          
          <li><a className="getstarted scrollto" href="#about">Get Started</a></li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav> */}

    </div>
  </header>
    )
}