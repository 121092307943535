import React, { useEffect } from "react";
import AOS from 'aos';
export default function Services() {

    useEffect(() => {
      AOS.init({
        // Configuration options go here
      });
    },[])
    return (
        <>
        <div className="row ">
          <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="200"></div>
          <div className="col-lg-2 col-md-6" data-aos="fade-up" data-aos-delay="200">
          <span  className="title-win p-1">Alpha</span>
            <div className="service-box blue">
              <i className="fa fa-desktop icon"></i>
              <h3>Windows</h3>
              {/* <p>Please click on the download button below to get for Windows. Once the download is complete, you can install it on your computer and start using it right away</p> */}
              <a href="https://pv-images-alpha.s3.us-west-2.amazonaws.com/senatus/senatus-win.exe" className="read-more"><span>Download</span> <i className="fa fa-download"></i></a>
              {/* <div className='row'>
                <div className='col-md-6'><a href="#" className="read-more"><span>Download 32 Bits</span> <i className="fa fa-download"></i></a></div>
                <div className='col-md-6'><a href="#" className="read-more"><span>Download 64 Bits</span> <i className="fa fa-download"></i></a></div>
              </div> */}
            </div>
          </div>

          <div className="col-lg-2 col-md-6" data-aos="fade-up" data-aos-delay="300">
          <span  className="title-mac p-1">Alpha</span>
            <div className="service-box orange">
              <i className="fa fa-apple icon"></i>
              <h3>MAC</h3>
              {/* <p className="read-more">Download</p> */}
              <a href="https://pv-images-alpha.s3.us-west-2.amazonaws.com/senatus/senatus-mac.dmg" className="read-more"><span>Download</span> <i className="fa fa-download"></i></a>
            </div>
          </div>

          <div className="col-lg-2 col-md-6" data-aos="fade-up" data-aos-delay="400">
          <span  className="title-linux p-1">Alpha</span>
            <div className="service-box green">
              <i className="fa fa-linux icon"></i>
              <h3>Linux</h3>
              {/* <p>Please click on the download button below to get for Linux. Once the download is complete, you can install it on your computer and start using it right away</p> */}
              <a href="https://pv-images-alpha.s3.us-west-2.amazonaws.com/senatus/senatus-linux.deb" className="read-more"><span>Download</span> <i className="fa fa-download"></i></a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6" data-aos="fade-up" data-aos-delay="200"></div>
          

        </div>
        </>
    )
}