import React from 'react';

const Footer = () => {
  return (
    <footer>
      <div className="container" style={{textAlign: "center", padding:"7px", color:"#FFF"}}>
        <p>&copy; {new Date().getFullYear()} Powered by PointsVille.</p>
      </div>
    </footer>
  );
};

export default Footer;