import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header";
import BannerSection from "./Components/BannerSection";
import Services from "./Components/Services";
import Footer from "./Components/Footer";

function App() {
  return (
    <div className="App dark_theme">
      <Header />
      <section id="hero" className="hero d-flex align-items-center dark_theme">
        <div className="container">
          <BannerSection />
        </div>
      </section>
      <section id="services" className="services dark_theme">
        <div className="container" data-aos="fade-up">
          <Services />
        </div>
      </section>
      <Footer/>
    </div>
  );
}

export default App;
